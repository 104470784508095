<template>
  <div>
    <div class="counter-top mb-5" ref="sideBarComponent">
      <div class="client-avatar text-center mb-2 pb-0" v-on:click="setFilters(false)">
        <img
          class="client-logo m-0"
          v-bind:src="client.logo"
        >
      </div>
      <div class="row side-bar-stats mt-3">
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('pending')">
          <h3>{{ticketStatistics.totalPending | zeroPad}}</h3>
          <small>Pending</small>
        </div>
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('acknowledged')">
          <h3>{{ticketStatistics.totalAcknowledged | zeroPad}}</h3>
          <small>Accepted</small>
        </div>
      </div>
      <div class="row side-bar-stats">
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('inProgress')">
          <h3>{{ticketStatistics.totalinProgress | zeroPad}}</h3>
          <small>In Progress</small>
        </div>
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('escalated')">
          <h3>{{ticketStatistics.totalEscalated | zeroPad}}</h3>
          <small>Escalated</small>
        </div>
      </div>
      <div class="row side-bar-stats">
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('resolved')">
          <h3>{{ticketStatistics.totalResolved | zeroPad}}</h3>
          <small>Resolved</small>
        </div>
        <div class="col col-12 col-md-12 cursor-pointer" v-on:click="setFilters('closed')">
          <h3>{{ticketStatistics.totalClosed | zeroPad}}</h3>
          <small>Closed</small>
        </div>
        <div class="col col-12 col-md-12">
          <h3>{{ticketStatistics.totalAgingOpen | zeroPad}}</h3>
          <small>Aging Open</small>
        </div>
        <div class="col col-12 col-md-12">
          <h3>{{ticketStatistics.totalAgingClosed | zeroPad}}</h3>
          <small>Aging Closed</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        user: this.$userData.user,
        client: {
          name: this.$userData.user.client ? this.$userData.user.client.name : 'iRipple Group of Companies',
          logo: this.$userData.user.client ? this.$userData.user.client.logo ? this.$userData.user.client.logo : this.$defaultIrippleLogo : this.$defaultIrippleLogo
        },
        interval: null,
        localStatistics: localStorage.getObject('ticketStatistics'),
        defaultFilters: {
          date: "",
          dateRange: [
            moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
          ],
          businessUnit: [],
          client: [],
          issueClassification: [],
          status: [],
          segment: [],
          label: [],
          priority: [],
          agentPriority: [],
          includeDate: false,
          isActive: []
        },
        ticketStatistics: {
          totalAcknowledged: 0,
          totalClosed: 0,
          totalCount: 0,
          totalEscalated: 0,
          totalPage: 0,
          totalPending: 0,
          totalResolved: 0,
          totalinProgress: 0,
          totalAgingOpen: 0,
          totalAgingClosed: 0
        }
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      setFilters: function(status){
        var params = this.defaultFilters
        this.$emit('hideSideOptions', 'showTicketsOptions')
        if(status){
          params.status = [status]
          localStorage.setObject('ticketFilters', params)
        }
        else{
          params.dateRange = this.$defaultDates
          localStorage.removeItem('ticketFilters')
        }
        this.getTotals()
        if(!((this.$route.path.startsWith("/tickets") || this.$route.path == "/") && window.location.pathname.substring(9,20) == ""))
          this.$router.push('/tickets')
        this.$emit('setTicketFilters', params)
        this.$alertify({
          group: 'notification',
          title: 'Ticket Filters',
          type: 'muted',
          text: status ? `Showing all ${this.$statuses.find(r => r.value === status).text.toLowerCase()} tickets for the current year` : 'Ticket filters has been reset'
        })
      },
      async getTotals() {
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/ticketTotals`, {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.ticketStatistics = {
            totalAcknowledged: response.data.totalAcknowledged,
            totalClosed: response.data.totalClosed,
            totalCount: response.data.totalCount,
            totalEscalated: response.data.totalEscalated,
            totalPage: response.data.totalPage,
            totalPending: response.data.totalPending,
            totalResolved: response.data.totalResolved,
            totalinProgress: response.data.totalinProgress,
            totalAgingOpen: response.data.totalAgingOpen,
            totalAgingClosed: response.data.totalAgingClosed,
          }
          localStorage.setObject('ticketStatistics', this.ticketStatistics)
        } catch (error) {
          console.log(error)
        }
      }
    },
    filters: {
      zeroPad: function(num){
        return String(num).padStart(5, '0')
      }
    },
    mounted: function() {
      if(this.localStatistics && this.localStatistics != {})
        this.ticketStatistics = this.localStatistics
      else
        this.getTotals()
      this.interval ||= setInterval(() => {
        this.getTotals()
      }, 30000)
    },
    beforeUnmount() {
      clearInterval(this.interval)
    }
  }
</script>
